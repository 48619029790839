import React from "react";
// import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

const Collaboration = ({ name, location, description }) => {
  return (
    <>
      <h3 style={{ marginBottom: 10 }}>
        {name}{" "}
        <span>
          <FaGlobe style={{ marginRight: 8, marginLeft: 4, paddingTop: 7 }} />
          {location}
        </span>
      </h3>
      <p style={{ maxWidth: 800 }}>{description}</p>
    </>
  );
};

export default Collaboration;
