import React from "react";
import { Link } from "gatsby";
// import { OutboundLink } from "gatsby-plugin-google-analytics";
import Head from "../components/head.js";
import Layout from "../components/layout";
import CollaborationEntry from "../components/collaboration-entry";

const Ocean = () => {
  return (
    <Layout>
      <Head title="Collaboration" />
      <h3>Collaborations</h3>
      <p className="aboutP">
        If you are an artist of any kind and are looking for collaborators for
        individual pieces / projects or an ongoing basis, use the{" "}
        <Link to="/contact">contact page</Link> to reach out and get connected
        if you can't otherwise reach the person or group.
      </p>
      <p className="aboutP">
        If you are looking for collaborators, also{" "}
        <Link to="/contact">contact</Link> the website to be listed on this
        page; with whatever information you would like listed.
      </p>

      <hr />

      <CollaborationEntry
        name="Gry Marić"
        location="Sydney, Australia"
        description="Looking for a body paint artist to paint me in green coloured paint and wrap me in a canvas. This has proven difficult for me to do on my own. I intend the final piece to be presented in public and possibly toured aroung the world"
      />

      <CollaborationEntry
        name="Curro Anselmo"
        location="Nashville, Tennessee"
        description=" I skateboard creatively and am looking for a videographer. Parkour skills are a plus but not necessary"
      />
      <CollaborationEntry
        name="Benji Friedman"
        location="Berkeley, California"
        description="I'd like to work with a poet on a synesthetic composite piece / project between images and words, with multimedia results. I'm interested in mythological images and archetypes so I'm coming from that background in terms of art."
      />
      <CollaborationEntry
        name="Sol Zientek"
        location="Kansas City, Missouri"
        description="I am a home gardener who makes leaf and flower pressings. If any painters would like to paint selections from my flower and leaf collection..."
      />
      <CollaborationEntry
        name="Saskia Sanaz Forsberg"
        location="San Francisco, California"
        description="I would like to work with a musician for a performance art piece. My most recent performances have featured candles, soap, and sunglasses. 🕯️🧼😎"
      />
      <CollaborationEntry
        name="Priscila Peel"
        location="Mendoza, Argentina"
        description="Soy un animador que acepta a cualquier persona del mundo que quiera contar su historia en imágenes. Podemos comunicarnos a través de Internet... I am an animator accepting anyone around the world who wants their story told in images. We can communicate through the internet."
      />
      <p>
        <em>
          please note, currently not all these "people" are necessarily real
        </em>
      </p>
    </Layout>
  );
};

export default Ocean;
